import '../styles/Header.css';
import lz from '../img/lz.svg'
import x from '../img/x.svg'
import Web3 from 'web3';
import { useState } from 'react';
import { config } from './config';

function Header({setAccount, setBalance, setters}) {
  const web3 = new Web3(window.ethereum)
  const[shortAcc, setShortAcc] = useState('Connect')
  

  const mintABI = [{"inputs":[{"internalType":"uint256","name":"amount","type":"uint256"}],"name":"ticketMint","outputs":[],"stateMutability":"payable","type":"function"}, {"inputs":[{"internalType":"address","name":"account","type":"address"},{"internalType":"uint256","name":"id","type":"uint256"}],"name":"balanceOf","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"}]

  async function getBalance(address){
    let bal = 0

    for(let [id, chain] of config.entries()){
        console.log(bal)
        const web3 = new Web3(chain.rpc)
        const contract = new web3.eth.Contract(mintABI, chain.contractAddress)

        const val = await contract.methods.balanceOf(address, 0).call()
        console.log(val)
        setters[id](Number(val))

        bal = bal + Number(val)
    }

    setBalance(bal)
    return bal
}

  async function tryConnect(){
    try{

      console.log('here')
      const accounts = await web3.eth.requestAccounts()
      setShortAcc(accounts[0].slice(0, 6) + '...' + accounts[0].slice(36, 42))
      setAccount(accounts[0])
      getBalance(accounts[0])
      return
    }
    catch(e){
      
    }
  }

  if(window.ethereum){
    tryConnect()
  }

  return (
    <div className="Header">
      <div className="head-wrap">
        <div className="logo">
          <div className="fronk-logo">
            Fronk World
          </div>
          <div className="x">
            <img src={x} alt="" />
          </div>
          <div className="lz-logo">
            <img src={lz} alt="" />
          </div>
        </div>
        <div className="connect" onClick={tryConnect}>
          {shortAcc}
        </div>
      </div>
    </div>
  );
}

export default Header;
