import '../styles/TransactionHistory.css';
import { useState } from 'react';
import { config } from './config';

function TransactionHistory({transactions}) {
    console.log(transactions)
    console.log('here')

    return (
    <div className="TransactionHistory">
        <div className="transactions-tag">Transactions</div>
        <div className="transactions">
            {transactions.length != 0? transactions.map(transaction =>{
                return(
                    <div className="transaction">
                        <div className="indicator"></div>
                        <div className="hash">
                            L0 TX: <span className='hash-link'><a target='_blank' href={config.filter(chain => chain.name === transaction.from)[0].blockscan + "tx/" + transaction.hash}>{transaction.hash}</a></span>
                        </div>
                        <div className="logs">
                            From: <span className='bold log'>{transaction.from}</span> To: <span className='bold log'>{transaction.to}</span> For <span className='bold log'>{transaction.amount} FKT</span>
                        </div>
                    </div>
                )
            }) :
            <div className='empty'>No transactions</div>}
        </div>
    </div>
    );
}

export default TransactionHistory;