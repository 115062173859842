import Web3 from 'web3';
import '../styles/Mint.css';
import { useEffect, useRef, useState } from 'react';
import { config } from './config';
/* global BigInt */

function Mint({address, balance, setBalance, setters, balances}) {
    const uweb3 = new Web3()
    const input = useRef()
    const [amount, setAmount] = useState(1)

    const chains = ["Arbitrum Nova", "Arbitrum", "BNB Chain", "Optimism"]

    const mintAddress = "0x390A43C68cB9d4529385c881FcD68C1b4CC202EF"
    const mintABI = [{"inputs":[{"internalType":"uint256","name":"amount","type":"uint256"}],"name":"ticketMint","outputs":[],"stateMutability":"payable","type":"function"}, {"inputs":[{"internalType":"address","name":"account","type":"address"},{"internalType":"uint256","name":"id","type":"uint256"}],"name":"balanceOf","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"}, {"inputs":[],"name":"price","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"}]

    async function switchTo(chain){
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: uweb3.utils.toHex(chain.chainId) }]
            }) 
        } 
        catch (err){
            if (err.code === 4902) {
                console.log('try')
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: chain.name,
                            chainId: uweb3.utils.toHex(chain.chainId),
                            nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
                            rpcUrls: [chain.rpc],
                            blockExplorerUrls: [chain.blockscan]
                        }
                    ]
                    }
                )
            }

            if(err.code === 4001){
                return false
            }
        }
    }

    async function getBalance(address){
        let bal = 0
    
        for(let [id, chain] of config.entries()){
            console.log(bal)
            const web3 = new Web3(chain.rpc)
            const contract = new web3.eth.Contract(mintABI, chain.contractAddress)
    
            const val = await contract.methods.balanceOf(address, 0).call()
            console.log(val)
            setters[id](Number(val))
            
            bal = bal + Number(val)
        }

        return bal
    }

    async function mint(){
        const web3 = new Web3(window.ethereum)
        const mintContract = new web3.eth.Contract(mintABI, mintAddress)

        const amount = input.current.value
        const price = await mintContract.methods.price().call()

        console.log(price)

        const value =  BigInt(price) * BigInt(amount)

        console.log(address)
        try{
            const receipt = await mintContract.methods.ticketMint(amount).send({from: address, value: value})
            console.log(receipt)
            setBalance(await getBalance(address))
        }
        catch(err){
            console.log(err.message)
        }
    }

    async function tryMint(){
        const status = await switchTo(config[chains.indexOf("Arbitrum Nova")])
        if(status == false){
            return
        }
        await mint()
        return
    }

    function containsOnlyNumbers(str) {
        return /^\d+$/.test(str);
    }

    function amountInput(event){
        if(containsOnlyNumbers(event.target.value) == false){
            return
        }

        setAmount(event.target.value)
    }

    function sub(){
        if(amount == 1){
            return
        }

        setAmount(amount - 1)
    }

    function add(){
        setAmount(amount + 1)
    }

    return (
    <div className="Mint">
        <div className="nft-about">
            <div className="nft-pic">
                <img className='pic' src="https://ipfs.io/ipfs/bafybeigwed56us6gmojwpgfxywxahy5sdssmcfqk7wbrc4blqk4tnlpdlu" alt="" />
            </div>
            <div className="info">
                <div className="total">
                    <div className="name">
                        Fronk Ticket
                    </div>
                    <div className="count">
                        You have: <span className='bold am'>{balance}</span> FKT
                    </div>
                </div>
                <div className="chains-balance">
                    {chains.map((chain, id) =>{
                        if(balances[id]){
                            return(
                                <>
                                    <div className="count">
                                        {chain}: <span className='bold am'>{balances[id]}</span> FKT
                                    </div>
                                </>
                            )
                        }
                        else{
                            <></>
                        }
                    })}
                </div>
            </div>
        </div>
        <div className="mint-function">
            <div className="mint-button button" onClick={tryMint}>
                Mint
            </div>
            <div className="counter">
                <div className="minus-wrap" onClick={sub}>
                    <div className="minus"></div>
                </div>
                <input ref={input} className="count-mint bridge-input" type="numeric" value={amount} onChange={e => amountInput(e)}/>
                <div className="plus" onClick={add}>
                </div>
            </div>
            <div className="value">
                {Number(amount * 0.0099).toFixed(4)} ETH
            </div>
        </div>
    </div>
  );
}

export default Mint;