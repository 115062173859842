import '../styles/SelectChain.css';

function SelectChain({srcChain, dstChain, setSrcChain, setDstChain, type, modalActive, setModalActive}) {
    const chains = ["Arbitrum Nova", "Arbitrum", "BNB Chain", "Optimism", "Polygon", "Base"]

    function selectChain(chain){
        if(type == 0){
            if(chain == dstChain){
                setDstChain(srcChain)
            }
            setSrcChain(chain)
        }

        if(type == 1){
            if(chain == srcChain){
                setSrcChain(dstChain)
            }
            setDstChain(chain)
        }

        setModalActive(false)
    }

    return (
    <div className={modalActive? "SelectChain active": "SelectChain"} onClick={()=>{setModalActive(false)}}>
        <div className="SelectChain-content" onClick={e => e.stopPropagation()}>
            <div className="big-tag chains-tag">
                Chains
            </div>
            <div className="chains">
                {
                    chains.map(chain =>{
                        return(
                            <div className="chain" onClick={()=>{selectChain(chain)}}>
                                {chain}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </div>
    );
}

export default SelectChain;