const arb_nova = {
    contractAddress: "0x390A43C68cB9d4529385c881FcD68C1b4CC202EF",
    chainId: 42170,
    rpc: "https://arbitrum-nova.publicnode.com",
    name: "Arbitrum Nova",
    blockscan: "https://nova.arbiscan.io/",
    lzEndpoint: "0x4EE2F9B7cf3A68966c370F3eb2C16613d3235245",
    lzChainId: 175,
    symbol: "ETH"
}

const arb = {
    contractAddress: "0xaC02f33C2b5672F2C48BfB940b592A1aeA557c7c",
    chainId: 42161,
    rpc: "https://arbitrum-one.publicnode.com",
    name: "Arbitrum",
    blockscan: "https://arbiscan.io/",
    lzEndpoint: "0x3c2269811836af69497E5F486A85D7316753cf62",
    lzChainId: 110,
    symbol: "ETH"
}

const bnb = {
    contractAddress: "0xff81A129b871Ca1255E23BaCF9DeeF1960aF3D39",
    chainId: 56,
    rpc: "https://bsc.publicnode.com",
    name: "BNB Chain",
    blockscan: "https://bscscan.com/",
    lzEndpoint: "0x3c2269811836af69497E5F486A85D7316753cf62",
    lzChainId: 102,
    symbol: "BNB"
}

const op = {
    contractAddress: "0x2D0738EedA5277F8d905fe13A9c8C1474c7ce8A0",
    chainId: 10,
    rpc: "https://optimism.llamarpc.com",
    name: "Optimism",
    blockscan: "https://optimistic.etherscan.io/",
    lzEndpoint: "0x3c2269811836af69497E5F486A85D7316753cf62",
    lzChainId: 111,
    symbol: "ETH"
}

const polygon = {
    contractAddress: "0x390A43C68cB9d4529385c881FcD68C1b4CC202EF",
    chainId: 137,
    rpc: "https://polygon.llamarpc.com",
    name: "Polygon",
    blockscan: "https://polygonscan.com/",
    lzEndpoint: "0x3c2269811836af69497E5F486A85D7316753cf62",
    lzChainId: 109,
    symbol: "MATIC"
}

const base = {
    contractAddress: "0x390A43C68cB9d4529385c881FcD68C1b4CC202EF",
    chainId: 8453,
    rpc: "https://base.publicnode.com",
    name: "Base",
    blockscan: "https://basescan.org/",
    lzEndpoint: "0xb6319cC6c8c27A8F5dAF0dD3DF91EA35C4720dd7",
    lzChainId: 184,
    symbol: "ETH"
}

export const config = [arb_nova, arb, bnb, op, polygon, base]