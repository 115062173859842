import '../styles/Main.css';
import Bridge from './Bridge.jsx';
import Mint from './Mint.jsx';
import { useState } from 'react';
import TransactionHistory from './TransactionHistory.jsx';

function Main({address, balance, setBalance, setters, balances}) {
    const[transactions, setTransactions] = useState(window.localStorage.getItem('transactions') ? JSON.parse(window.localStorage.getItem('transactions')) : [])

    return (
    <>
        <div className="Main">
            <Bridge address={address} transactions={transactions} setTransactions={setTransactions} balances={balances}/>
            <Mint address={address} balance={balance} setBalance={setBalance} setters={setters} balances={balances}/>
        </div>
        <TransactionHistory transactions={transactions}/>
    </>
    );
}

export default Main;