import '../styles/Bridge.css';
import {useEffect, useState} from 'react';
import SelectChain from './SelectChain.jsx';
import { config } from './config';
import Web3 from 'web3';

function Bridge({address, transactions, setTransactions}) {
    const[modalActive, setModalActive] = useState(0)
    const[type, setType] = useState(0)
    const[srcChain, setSrcChain] = useState("Arbitrum Nova")
    const[dstChain, setDstChain] = useState("Arbitrum")
    const[amount, setAmount] = useState(1)
    const[lzFee, setLzFee] = useState(0)

    const uweb3 = new Web3()

    const chains = ["Arbitrum Nova", "Arbitrum", "BNB Chain", "Optimism", "Polygon", "Base"]
    const bridgeABI = [{"inputs":[{"internalType":"uint256","name":"id","type":"uint256"},{"internalType":"uint256","name":"amount","type":"uint256"},{"internalType":"uint16","name":"chainId","type":"uint16"}],"name":"bridge","outputs":[],"stateMutability":"payable","type":"function"}, {"inputs":[],"name":"bridgeFee","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"}]
    const endpointABI = [{"inputs":[{"internalType":"uint16","name":"_dstChainId","type":"uint16"},{"internalType":"address","name":"_userApplication","type":"address"},{"internalType":"bytes","name":"_payload","type":"bytes"},{"internalType":"bool","name":"_payInZRO","type":"bool"},{"internalType":"bytes","name":"_adapterParams","type":"bytes"}],"name":"estimateFees","outputs":[{"internalType":"uint256","name":"nativeFee","type":"uint256"},{"internalType":"uint256","name":"zroFee","type":"uint256"}],"stateMutability":"view","type":"function"}]

    async function switchTo(chain){
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: uweb3.utils.toHex(chain.chainId) }]
            }) 
        } 
        catch (err){
            if (err.code === 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: chain.name,
                            chainId: uweb3.utils.toHex(chain.chainId),
                            nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
                            rpcUrls: [chain.rpc],
                            blockExplorerUrls: [chain.blockscan]
                        }
                    ]
                    }
                )
            }

            if(err.code === 4001){
                return false
            }
        }
    }

    function openModal(pickType){
        setType(pickType)
        setModalActive(true)
    }

    function containsOnlyNumbers(str) {
        return /^\d+$/.test(str);
    }

    function amountInput(event){
        if(containsOnlyNumbers(event.target.value) == false){
            return
        }

        setAmount(event.target.value)
    }

    async function estimateFee(){
        let from = config[chains.indexOf(srcChain)]
        let to = config[chains.indexOf(dstChain)]

        const web3 = new Web3(from.rpc)
        const endpoint = new web3.eth.Contract(endpointABI, from.lzEndpoint)
        const bridge = new web3.eth.Contract(bridgeABI, from.contractAddress)

        const payload = web3.eth.abi.encodeParameters(["address", "uint256", "uint256"], [address? address : (web3.eth.accounts.create()).address, 0, amount? amount : 1])
        const fee = await endpoint.methods.estimateFees(to.lzChainId, to.contractAddress, payload, false, "0x").call()
        const fronkFee = await bridge.methods.bridgeFee().call()

        console.log(fee.nativeFee)
        console.log(fronkFee)

        return fee.nativeFee + fronkFee
    }

    async function startBridge(){
        let from = config[chains.indexOf(srcChain)]
        let to = config[chains.indexOf(dstChain)]

        const status = await switchTo(from)

        if(status === false){
            return
        }

        const web3 = new Web3(window.ethereum)
        const bridge = new web3.eth.Contract(bridgeABI, from.contractAddress)

        const value = await estimateFee()
        let transactionsCopy

        try{
            const receipt = await bridge.methods.bridge(0, amount, to.lzChainId).send({from: address, value: value})
            console.log(receipt)

            transactionsCopy = transactions.slice(0)
            transactionsCopy.push({hash: receipt.transactionHash, from: from.name, to: to.name, amount: amount})

            window.localStorage.setItem("transactions", JSON.stringify(transactionsCopy))
            setTransactions(transactionsCopy)
            
            console.log(transactions)
        }
        catch(err){
            console.log(err.message)
        }

        if(transactionsCopy){
            setTransactions(transactionsCopy)
        }
    }

    useEffect(()=>{
        async function updateFee(){
            const web3 = new Web3()
            const fee = await estimateFee()
            console.log(fee)
            setLzFee(Number(web3.utils.fromWei(fee, "ether").toString()).toFixed(6))
        }

        updateFee()
    }, [dstChain, srcChain])

    function sub(){
        if(amount == 1){
            return
        }

        setAmount(amount - 1)
    }

    function add(){
        setAmount(amount + 1)
    }

    return (
        <div className="Bridge">
            <SelectChain srcChain={srcChain} dstChain={dstChain} setSrcChain={setSrcChain} setDstChain={setDstChain} type={type} modalActive={modalActive} setModalActive={setModalActive}/>
            <div className="bridge-tag">
                Bridge with LayerZero 
            </div>
            <div className="route">
                <div className="from-input chain-input">
                    <div className="from tag">
                        From: 
                    </div>
                    <div className="chain-pick">
                        <div className="active-chain" onClick={()=>{openModal(0)}}>
                            {srcChain}
                        </div>
                    </div>
                </div>
                <div className="to-input chain-input">
                    <div className="to tag">
                        To:
                    </div>
                    <div className="chain-pick">
                        <div className="active-chain" onClick={()=>{openModal(1)}}>
                            {dstChain}
                        </div>
                    </div>
                </div>
            </div>
            <div className="counter-bridge">
                <div className="amount tag">
                    Amount:
                </div>
                <div className="counter">
                    <div className="minus-wrap" onClick={sub}>
                        <div className="minus sub"></div>
                    </div>
                    <input className="count-bridge" type="numeric" value={amount} onChange={e => amountInput(e)}/>
                    <div className="plus add" onClick={add}>
                    </div>
                </div>
            </div>
            <div className="fee tag">
                Fee: <span className="bold">{lzFee} {config[chains.indexOf(srcChain)].symbol}</span>    
            </div>
            <div className="button-wrap">
                <div className="bridge-button button" onClick={startBridge}>
                    Bridge
                </div>
            </div>
        </div>
    );
}

export default Bridge;