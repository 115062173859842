import { useState } from 'react';
import './App.css';
import Header from './components/Header.jsx';
import Main from './components/Main.jsx';

function App() {
  const[account, setAcount] = useState()
  const[balance, setBalance] = useState(0)
  const[transactions, setTransactions] = useState([])

  const [novaAmount, setNovaAmount] = useState(0)
  const [arbAmount, setArbAmount] = useState(0)
  const [bnbAmount, setBnbAmount] = useState(0)
  const [opAmount, setOpAmount] = useState(0)
  const [polyAmount, setPolyAmount] = useState(0)
  const [baseAmount, setBaseAmount] = useState(0)

  const balances = [novaAmount, arbAmount, bnbAmount, opAmount, polyAmount, baseAmount]
  const setters = [setNovaAmount, setArbAmount, setBnbAmount, setOpAmount, setPolyAmount, setBaseAmount]

  return (
    <div className="App">
      <Header setAccount={setAcount} setBalance={setBalance} setters={setters}/>
      <Main address={account} balance={balance} transactions={transactions} setBalance={setBalance} setTransactions={setTransactions} setters={setters} balances={balances}/>
    </div>
  );
}

export default App;
